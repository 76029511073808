.pomopara {
  padding-top: 110px;
  margin-left: 200px;
  margin-right: 200px;
  align-items: center;
}


.pomopara h1 {
  text-align: center;
  font-weight: bold;
  color: #1fc12d;
  font-size: 34px;
  font-family: "Josefin Sans", sans-serif;
  text-shadow: 1px 1px 2px rgb(193, 76, 96);
}

.pomopara p {
  padding-top: 10px;
  color: black;
  font-size: 20px;
  color: #565656;
  line-height: 28px;
  font-family: "Poppins", sans-serif;
}

.pomopacking h1 {
  font-size: 30px;
  font-weight: bold;
  color: #1fc12d;
  font-family: "Josefin Sans", sans-serif;
  text-align: start;
  margin-top: 50px;
  text-shadow: 1px 1px 2px rgb(193, 76, 96);
}

.pomochart h1 {
  font-size: 30px;
  font-weight: bold;
  color: #1fc12d;
  font-family: "Josefin Sans", sans-serif;
  text-align: start;
  margin-top: 50px;
  text-shadow: 1px 1px 2px rgb(193, 76, 96);
}

.pomo-box {
  padding: 70px 184px;
}

.pomo-single {
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 2px 2px rgba(223, 157, 157, 0.5);
}

@media screen and (max-width: 640px) {

  .pomopacking h1 {
      margin-left: 10px;
  }
  .pomopara {
    padding-top: 70px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: justify;
  }

.pomopara P{
  font-size: 16px;
}

  .pomo-box {
    padding: 0px 0px;
}

.pomochart {
  margin-left: 10px;
}

.row>* {
  flex-shrink: 0;
  max-width: none;
  padding-right: calc(var(--mdb-gutter-x) * 0.5);
  padding-left: calc(var(--mdb-gutter-x) * 0.5);
  margin-top: var(--mdb-gutter-y);
}

.pomoicon{
  margin-top: -5px;
}


}