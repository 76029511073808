.trow h1 {
    margin-left: 30px;
}

.table-main {
    margin-left: 30px;
    background: white;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(66, 68, 90, 1);
    width: 95%;
    margin-bottom: 10px;
}

.tableFixHead {
    overflow: scroll;
    height: 500px;
    width: 100%;
}

.atable {
    border-collapse: collapse;
    width: 100%;
    text-decoration: none;
}

.atable th,
.atable td {
    max-width: 300px;
    padding: 7px 16px;
    border: 1px solid #ddd;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.atable thead {
    position: sticky;
    padding: 5px;
    inset-block-start: 0;
    background-color: #ddd;
    box-shadow: 1px 1px 5px grey;
}

.atable tbody {
    font-weight: 500;
}

/* ########################## Table ###################### */


.search-container {
    display: flex;
    padding: 10px;
    /* margin-bottom: 10px; */
    background-color: rgb(255, 255, 255);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.insearch {
    border-radius: 10px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px 10px;
    /* border: 1px solid #1E90FF; */
}

.searchbtn {
    font-size: 20px;
    border-radius: 10px;
    margin-right: 20px;
    cursor: pointer;
    background: transparent;
    border: none;
    float: right;
}

/* Custom styling for the dropdown */
.table-footer {
    padding: 8px;
    /* margin-top: 10px; */
    background-color: rgb(255, 255, 255);
    justify-content: flex-end;
    display: flex;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.dropdown-container select {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

.dropdown-container label {
    margin-right: 10px;
    font-size: 14px;
    white-space: nowrap;
}

.dropdown-container {
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.pagination-buttons button.active {
    background-color: #868b86;
    color: white;
    border: 1px solid #868b86;
}

.pagination-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

.pagination-buttons button {
    padding: 10px 15px;
    margin: 0 5px;
    background-color: #868b86;
    color: #fff;
    border: 1px solid #868b86;
    border-radius: 5px;
    cursor: pointer;
}

.pagination-buttons button:hover {
    background-color: #666766;
    border-color: #666766;
}

.pagination-buttons button[disabled] {
    background-color: #bdc3c7;
    color: #000000;
    border-color: #bdc3c7;
    cursor: not-allowed;
}

@media (max-width:1115px) {
    .main {
        width: calc(100% - 60px);
        left: 60px;
    }
}


@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
    .sidebar.table-main {
        width: 95%;
    }
}

@media (min-width: 768px) and (max-width: 1024px){
    .table-main{
        margin-left: 18px;
    }
}

@media (max-width: 480px) {
    .table-main{
        margin-left: 8px;
    }
    .table-footer{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 15px;
    }
    .pagination-buttons {
        margin-right: 7px;
        margin-bottom: 2px;
    }
}