/* Reset some default styles */
body,
h1,
h2,
h3,
p,
ul,
li {
  margin: 0;
  padding: 0;
}

/* Basic styling for the navigation */
nav {
  background-color: white;
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 100;
}

nav ul {
  list-style: none;
  display: flex;
  float: right;
  margin-top: -37px;
  margin-bottom: 1rem;
}

nav ul li {
  margin-right: 20px;
}

nav ul li a {
  text-decoration: none;
  color: rgb(14, 9, 9);
  font-weight: bold;
}

/* Style for the responsive menu toggle button */
.checkbtn {
  font-size: 30px;
  color: rgb(13, 11, 11);
  cursor: pointer;
  display: none;
}

/* Style for the logo and text in the navigation bar */
.mainlogo {
  display: flex;
  align-items: center;
}

.logo,
.text {
  max-height: 50px;
  /* Adjust the height as needed */
  margin-right: 10px;
}

/* Style for the dropdown menu */
.submenu {
  display: none;
  position: absolute;
  background-color: white;
  padding: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.submenu-toggle {
  display: flex !important;
  flex-direction: column;
  margin-top: 0px;
}

.topmenu:hover .submenu {
  margin-top: 0px;
}

.submenu li {
  margin: 8px 0;
}

ol,
ul,
dl {
  margin-top: -37px;
  margin-bottom: 1rem;
}

/* Animation for the main content section */
.animated {
  opacity: 0;
}

/* Animation for the scrolling reveal effect */
.animate {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.log-out {
  font-size: 16px;
  font-weight: 600;
  color: rgb(14, 9, 9);
}

.log-out:hover {
  color: #00bcd4;
  cursor: pointer;
}

/* Active link styling */
.active {
  color: #00bcd4;
}

/* Media query for responsive design */
@media screen and (max-width: 768px) {
  nav ul {
    flex-direction: column;
    display: none;
    width: 100%;
    height: 100vh;
    text-align: start;
    position: absolute;
    top: 50px;
    left: -100%;
    background-color: white;
    transition: left 0.3s ease;
  }

  .mainfield {
    margin-top: 0px;
  }

  nav ul.active {
    display: flex;
    top: 0;
    left: 0;
  }

  .checkbtn {
    display: block;
  }

  nav ul li {
    margin-right: 0;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .submenu { 
    position: static;
    display: none;
    height: 21vh;
    background-color: white;
  }

  .submenu {
    display: none;
    background-color: white;
    padding: 6px;
    padding-left: 20px;
    box-shadow: none ;
  }
  
  .submenu-toggle {
    display: block !important;
  }

  .nav-links.responsive {
    width: 100%;
    height: 100vh;
    left: 0;
  }

  .nav-links {
    right: 0;
  }

  .sub-none{
    display: none;
  }
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  .logbtn {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .checkbtn {
    font-size: 30px;
    color: #292626;
    cursor: pointer;
    display: flex;
    margin-left: 70px;
  }

  .mainlogo {
    display: flex;
    align-items: center;
  }

  .mainfield {
    margin-top: 0px;
  }

  .logo,
  .text {
    max-height: 35px;
    /* Adjust the height as needed */
    margin-right: 10px;
  }

  nav ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 0px;
  }

  .nav-links.responsive {
    width: 100%;
    height: 100vh;
  }

  .nav-links {
    right: 0;
  }

}
