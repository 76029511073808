.grapes-contain {
    overflow: hidden;
}

.grapescontent {
    padding-top: 110px;
    margin-left: 200px;
    margin-right: 200px;
    align-items: center;
}

.grapescontent p {
    padding-top: 10px;
    font-size: 20px;
    color: #080808;
    line-height: 28px;
    margin-top: 10px;
    font-family: "Poppins", sans-serif;
}

.grapes-single h5 {
    margin-left: 10px;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.grapescontent h1 {
    text-align: center;
    font-weight: bold;
    color: #1fc12d;
    font-size: 34px;
    font-family: "Josefin Sans", sans-serif;
    text-shadow: 1px 1px 2px rgb(193, 76, 96);
}

.grapes-table {
    overflow: hidden;
    padding: 10px;
}

.grapes-box {
    padding: 0px 184px;
}

.grapchart h1 {
    margin-top: 40px;
    font-size: 30px;
    font-weight: bold;
    color: #1fc12d;
    font-family: "Josefin Sans", sans-serif;
    text-align: start;
    text-shadow: 1px 1px 2px rgb(193, 76, 96);
}

.grapes-single {
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    margin-bottom: 30px;
}

.grapespacking h1 {
    font-size: 30px;
    font-weight: bold;
    color: #1fc12d;
    font-family: "Josefin Sans", sans-serif;
    text-align: start;
    margin-top: 50px;
    padding-bottom: 30px;
    text-shadow: 1px 1px 2px rgb(193, 76, 96);
}

.grapes_main thead tr th,
.grapes_main td {
    border: 1px solid black;
    padding: 8px;
    text-align: center;
}

.grapes_main th {
    background-color: #82DC8B;
}

.sticky-cell {
    position: -webkit-sticky;
    /* For Safari */
    position: sticky;
    left: 0;
    background-color: white;
    /* Ensure the sticky cells have a background color */
    z-index: 1;
    /* Ensure the sticky cells are above other cells */
    border: 1px solid black;
}

@media (min-width: 992px) {
    .col-lg-3 {
        flex: 0 0 auto;
        width: 26%;
        margin-left: 60px;
    }
}


@media screen and (max-width: 640px) {

    .grapes-box {
        width: 100%;
        overflow-x: hidden;
    }

    .grapespacking h1 {
        margin-left: 10px;
    }

    .grapescontent {
        padding-top: 70px;
        margin-left: 10px;
        margin-right: 10px;
        text-align: justify;
    }

    .grapescontent p {
        font-size: 16px;
    }

    .grapes-box {
        padding: 0px 0px;
    }

    .grapes-single h5 {
        margin-left: 0px;
        text-align: center;
    }

    .grapchart {
        padding: 10px;
    }

    .row>* {
        flex-shrink: 0;
        max-width: none;
        padding-right: calc(var(--mdb-gutter-x) * 0.5);
        padding-left: calc(var(--mdb-gutter-x) * 0.5);
        margin-top: var(--mdb-gutter-y);
    }

    .packages {
        margin-left: 30px;
        width: 73%;
    }

    .grapes_container {
        overflow-x: hidden;
    }

    .grapes-table {
        padding: 0px;
        width: 95%;
    }

    .grapes_main {
        border-spacing: 0;
        display: block;
        width: 100%;
        border-collapse: collapse;
        overflow: scroll;
    }

    .grapes_main th,
    .grapes_main td {
        border: 1px solid black;
        padding: 8px;
        text-align: center;
    }

    .sticky-cell img {
        max-width: 20px;
        margin-right: 30px;
        /* Adjust image size as needed */
    }

    .grapes_main th {
        background-color: #82DC8B;
    }

    .sticky-cell {
        position: sticky;
        left: 0;
        border-right: 1px solid #f53131;
        background-color: rgb(239, 236, 236);
        z-index: 1;
        border: 1px solid black;
    }

    .grapes_main tbody tr:nth-child(even) {
        background-color: #f9f9f9;
    }

    .grapes_main tbody tr:hover {
        background-color: #ddd;
    }
}