.maincontact {
  font-family: 'Poppins', sans-serif;
  width: 100%;
  background: url("../images/Contact-us.jpg") center;
  background-size: cover;
  position: relative;
  height: 100vh;
}

.contactcontain {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.contactcontain2 h2 {
  font-family: 'Kalam', cursive;
  text-align: center;
  color: rgb(255, 255, 255);
  margin-bottom: 40px;
}

.section-header {
  margin-bottom: 50px;
  text-align: center;
}

.section-header h2 {
  color: #FFF;
  font-weight: bold;
  font-size: 3em;
  margin-bottom: 20px;
}

.contactrow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.contact-info {
  margin-left: 50px;
  width: 40%;
}

.contact-info-item {
  display: flex;
  margin-bottom: 30px;
}

.contact-info-icon {
  height: 70px;
  width: 70px;
  background-color: #fff;
  text-align: center;
  border-radius: 50%;
}

.contact-info-icon i {
  font-size: 30px;
  line-height: 70px;
}

.contact-info-content {
  margin-left: 20px;
}

.contact-info-content h4 {
  color: #1da9c0;
  font-size: 1.4em;
  margin-bottom: 5px;
}

.contact-info-content p {
  color: #ffffff;
  font-size: 1em;
}

.contact-form {
  background-color: #fff;
  padding: 40px;
  width: 45%;
  padding-bottom: 20px;
  box-shadow: 0px 1px 0px 0px rgb(255, 255, 255), 1px 0px 0px 0px rgb(255, 253, 253), 1px 2px 0px 0px rgb(255, 253, 253), 2px 1px 0px 0px rgb(255, 253, 253), 2px 3px 0px 0px rgb(255, 253, 253), 3px 2px 0px 0px rgb(255, 253, 253), 3px 4px 0px 0px rgba(0, 0, 0, 0.22), 4px 3px 0px 0px rgba(0, 0, 0, 0.22), 4px 5px 0px 0px rgba(0, 0, 0, 0.22), 5px 4px 0px 0px rgba(0, 0, 0, 0.22), 5px 6px 0px 0px rgba(0, 0, 0, 0.22), 6px 5px 0px 0px rgba(0, 0, 0, 0.22), 6px 7px 0px 0px rgb(252, 251, 251), 7px 6px 0px 0px rgb(255, 253, 253), 7px 8px 0px 0px rgb(255, 255, 255), 8px 7px 0px 0px rgb(255, 255, 255);
}

.contact-form h2 {
  font-weight: bold;
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
}

.contact-form .input-box {
  position: relative;
  width: 100%;
  margin-top: 20px;
}

.contact-form .input-box input,
.contact-form .input-box textarea {
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
}

.contact-form .input-box span {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  font-size: 16px;
  color: #666;
  transition: 0.3s ease;
  pointer-events: none;
}

.contact-form .input-box input[type="submit"] {
  width: 100%;
  background: #00bcd4;
  color: #FFF;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
  border-radius: 8px;
  border: 1px solid #00bcd4;
  transition: 0.5s;
}

.contact-form .input-box input[type="submit"]:hover {
  background: #FFF;
  color: #00bcd4;
}

@media screen and (max-width: 640px) {

  
  .maincontact {
    background-color: #1da9c0;
  }
  .contactrow {
    flex-direction: column;
  }

  .section-header {
    margin-bottom: 00px;
  }

  .contactcontain2 h2 {
    margin-bottom: 20px;
  }

  .contact-info {
    order: 2;
    display: none;
    margin-bottom: 40px;
    width: 90%;
    margin-left: 0;
  }

  .contact-form {
    order: 1;
    width: 90%;
    margin-bottom: 30px;
    box-shadow: 0px 1px 0px 0px rgb(255, 255, 255), 1px 0px 0px 0px rgb(255, 253, 253), 1px 2px 0px 0px rgb(255, 253, 253), 2px 1px 0px 0px rgb(255, 253, 253), 2px 3px 0px 0px rgb(255, 253, 253), 3px 2px 0px 0px rgb(255, 253, 253), 3px 4px 0px 0px rgba(0, 0, 0, 0.22), 4px 3px 0px 0px rgba(0, 0, 0, 0.22), 4px 5px 0px 0px rgba(0, 0, 0, 0.22), 5px 4px 0px 0px rgba(0, 0, 0, 0.22), 5px 6px 0px 0px rgba(0, 0, 0, 0.22), 6px 5px 0px 0px rgba(0, 0, 0, 0.22), 6px 7px 0px 0px rgb(252, 251, 251), 7px 6px 0px 0px rgb(255, 253, 253), 7px 8px 0px 0px rgb(255, 255, 255), 8px 7px 0px 0px rgb(255, 255, 255);
  }

  .maincontact {
    height: auto;
  }
}