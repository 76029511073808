.dis {
  font-family: "Poppins", Arial, sans-serif;
  background: #fff;
  font-size: 18px;
  line-height: 1.8;
  font-weight: 400;
  color: gray;
}

.about-box-main {
  padding-top: 110px;
}

.btun {
  background-color: #70a9d0;
  padding: 10px;
  border-radius: 7px;
  color: white;
}

.hvr-hover:hover {
  box-shadow: calc(0.0.2, 0.3)rgb(13, 13, 13);
}

.breadcrumb-item {
  padding: 10px;
  background-color: yellow;
}

.card-title {
  font-weight: 600;
}

.card1-group {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  gap: 10px;

}

.card1 {
  height: 400px;
  border-radius: 10px;
  margin-top: 10px;
  background-color: rgb(255, 255, 255);
  color: rgb(28, 149, 1);
  padding: 10px;
  box-shadow: 0px 0px 4px 0px rgb(44, 45, 44);
}


.carddis {
  font-weight: 500;
  font-size: 17px;
  color: rgb(40, 91, 29);
}

/* ?????????????????????????? */




:root {
  /* colors */
  --clr-white: hsl(220, 37%, 100%);
  --clr-surface: hsl(220, 37%, 97%);
  --clr-text: hsl(244, 44%, 14%);

  --clr-yellow-400: hsl(42, 99%, 69%);
  --clr-yellow-300: hsl(47, 100%, 90%);
  --clr-purple-400: hsl(280, 96%, 45%);
  --clr-purple-300: hsl(282, 89%, 90%);
  --clr-cyan-400: hsl(198, 99%, 49%);
  --clr-cyan-300: hsl(240, 100%, 90%);

  /* font families */
  --ff-base: "IBM Plex Sans", sans-serif;
  --ff-accent: "Young Serif", serif;

  /* font weights */
  --fw-regular: 500;
  --fw-bold: 600;
  --fw-black: 700;

  /* font sizes */
  --fs-300: 1rem;
  --fs-400: 1.125rem;
  --fs-500: 1.375rem;
  --fs-600: 1.75rem;
  --fs-700: 2.25rem;
}


body {
  background: var(--clr-surface);
  color: var(--clr-text);
  font-family: var(--ff-base);
  font-size: var(--fs-300);
}

.headline {
  padding-top: 100px;
}

.fresh {
  font-weight: 600;
}

.fresh span {
  color: #38b000;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.subtitle {
  font-size: var(--fs-400);
}



.even-columns {
  display: grid;
  gap: 3rem;
}

@media screen and (max-device-width: 640px) {
  .about-box-main {
    padding-top: 0px;
  }

  .headline {
    padding-top: 60px;
  }

  .headline1{
    padding-top: 0px;
  }

  .dis {
    font-size: 16px;
    text-align: justify;
  }

  .som-about {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
    font-size: 16px;
  }

  .som-vision {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
  }
}