@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  background-color: #ffffff;
  text-align: justify;
}



.fruits p {
  width: 100%;
  text-align: justify;
  font-size: 20px;
  line-height: 28px;
  color: #565656;
}

.layout_padding {
  padding: 75px 0;
}

.layout_padding2 {
  padding: 45px 0;
}

.layout_padding2-top {
  padding-top: 45px;
}


.layout_padding2-bottom {
  padding-bottom: 45px;
}

.layout_padding-top {
  padding-top: 75px;
}

.layout_padding-bottom {
  padding-bottom: 75px;
}


.custom_dark-btn,
.custom_orange-btn {
  display: inline-block;
  padding: 10px 0;
  text-align: center;
  width: 30%;
  outline: none;
  color: #0dff00;
  border: 1px solid transparent;
}

.custom_dark-btn {
  color: #ffffff;
  font-size: large;
  font-weight: 600;
  font-family: 'Courier New', Courier, monospace;
  background-color: #2cd263;
  border-radius: 25px;
}

/* .custom_dark-btn:hover {
  border: 1px solid #24ac63;
  color: #24ac63;
  background-color: transparent;
}
 */

.custom_heading {
  text-align: center;
  font-weight: bold;
  color: #1fc12d;
  font-size: 34px;
  font-family: "Josefin Sans", sans-serif;
}

.custom_heading-text {
  text-align: center;
}

.fruit_detail-box h3 {
  font-family: 'Courier New', Courier, monospace;
  font-weight: 700;
}

.fruit_detail-box p {
  width: 100%;
  text-align: justify;
  font-size: 20px;
  line-height: 28px;
  color: #565656;
}

.fruitcontent {
  display: flex;
  flex-wrap: wrap;
}

.roww {
  flex: 50%;
}

.fruit_detail-box .button {
  text-align: center;
  margin-bottom: 10px;
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
 

  .custom_heading {
     font-size: 25px;
  }

  .fruit_section {
    padding: 10px;
  }

  .fruits p{
    font-size: 16px;
  }

  .container {
    width: 100%;
    overflow-x: hidden;
  }

  .layout_padding-top {
    padding-top: 45px;
  }

  .custom_dark-btn, .custom_orange-btn {
    display: inline-block;
    padding: 10px 0;
    text-align: center;
    width: 57%;
    outline: none;
    font-weight: 800;
    color: white;
    border: 1px solid transparent;
}

  .fruit_detail-box{
   width: 95%;
  }

  .fruitcontent p{
    font-size: 15px;
  }
  .grapes{
    margin-left: 30px;
  }

  .guava{
    margin-top: 50px;
  }

  .banana{
    margin-top: 50px;
  }
}