@import url('https://fonts.googleapis.com/css2?family=Fanwood+Text:ital@0;1&family=Noto+Sans+Display:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.footer_logo {
  margin-left: 120px;
  width: 50px;
  height: 70px;
  margin-bottom: 20px;
}

.footer_text {
  margin-left: 10px;
  width: 260px;
  height: 50px;
}

.low-opacity-bg-image {
  position: relative;
  isolation: isolate;
}

.low-opacity-bg-image::after {
  content: "";
  position: absolute;
  inset: 0;
  opacity: 0.85;
}

.position-relative {
  font-family: Open Sans;
}

.position-relative p {
  font-weight: 500;
  font-size: 17px;
}

.mainmenu {
  font-family: "Noto Sans", sans-serif;
  font-size: 18px;
  color: aqua;
}

.mainmenu-sub {
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: black;
}

.copyright {
  font-weight: 600;
  font-size: 16px;
}

.footercontaner {
  background: rgba(255, 255, 255, 0.4);
  width: 100%;
  height: 430px;
}

@media screen and (max-device-width: 640px) {

  .footer_logo {
    margin-left: 0px;
    width: 40px;
    height: 50px;
  }

  .footer_text {
    margin-left: 15px;
    width: 230px;
    height: 45px;
  }

  .position-relative {
    width: 100%;
    font-size: 10px;
    /* The width is 100%, when the viewport is 800px or smaller */
  }


  p {
    color: black;
  }

  .mainmenu {
    margin-top: 25px;
  }

  .footercontaner {
    background: rgba(255, 255, 255, 0.4);
    width: 100%;
    height: 100vh;
  }

  .d-flex {
    display: block !important;
  }

  .mb-4 {
    margin-bottom: 0rem !important;
  }
}