.spicesparat {
    padding-top: 110px;
    margin-left: 200px;
    margin-right: 200px;
    align-items: center;
}

.spicesparat p {
    width: 100%;
    text-align: justify;
    font-size: 20px;
    line-height: 28px;
    color: #565656;
}

.spicesparat h1{
    text-align: center;
    font-weight: bold;
    color: #1fc12d;
    font-size: 35px;
    font-family: "Josefin Sans", sans-serif;
}

.spices p {
    margin-top: 10px;
}

.spiceschart  h1 {
    font-weight: bold;
    color: #1fc12d;
    font-size: 35px;
    font-family: "Josefin Sans", sans-serif;
}

.img-spices{
    width: 100%;
}

@media screen and (max-device-width: 640px) {
    .spicesparat {
        text-align: justify;
    }

    .spicesparat {
        padding: 10px;
        padding-top: 70px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .spicesparat p {
        width: 100%;
        text-align: justify;
        font-size: 16px;
        line-height: 28px;
        color: #565656;
    }

    .guava-box {
        padding: 0px;
    }

    .spiceschart {
        padding: 10px;
    }
    .spiceschart h1 {
        font-weight: bold;
        color: #1fc12d;
        font-size: 25px;
        font-family: "Josefin Sans", sans-serif;
    }
    .img-spices {
        margin-left: 30px;
    }

    td .img-fluid {
        float: right;
        margin-right: 0px;
    }

    .table-container {
        overflow-x: visible;
        width: 340px;                
    }

    .table-container table, 
    .table-container thead,
    .table-container tbody, 
    .table-container th,
    .table-container td, 
    .table-container tr {
        display: block;
        
    }

    .table-container thead tr {
        position: absolute;
               width: 40%;
    }

    .table-container tr {
        margin: 0 0 0.5rem 0;
    }

   .table-container td {
        border: none;
        position: relative;
        padding-left: 50%;
        text-align: center;
        border: 1px solid rgb(95, 94, 94);
    }

    .table-container td:before {
        /* content: attr(data-label); */
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 19px;
        font-weight: bold;
        text-align: left;
        /* background-color: #1fc12d;
        border-right: 1px solid black; */
    }

    .img-raisins {
        display: block;
        /* margin: 0px auto; */
        margin-left: -5px;
    }
}