.guava-single button {
    display: flex;
    align-self: flex-end;
    font-size: -2.45rem;
    font-weight: 500;
    color: #3983BA;
    background-color: #ffffff;
    border: 1px solid #c4c4c4;
    border-radius: 0.2rem;
    margin-top: 0.5rem;
    margin-left: 170px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  
  .guava-single button:hover {
    color: #ffffff;
    background-color: #3983BA;
  }

  