.guavaparat {
  padding-top: 110px;
  margin-left: 200px;
  margin-right: 200px;
  align-items: center;
}

.guavaparat h1 {
  text-align: center;
  font-weight: bold;
  color: #1fc12d;
  font-size: 34px;
  font-family: "Josefin Sans", sans-serif;
  text-shadow: 1px 1px 2px rgb(193, 76, 96);
}

.guavaparat p {
  padding-top: 10px;
  color: black;
  font-size: 20px;
  color: #565656;
  line-height: 28px;
  margin-top: 10px;
  font-family: "Poppins", sans-serif;
}

.guavapacking h1 {
  font-size: 30px;
  font-weight: bold;
  color: #1fc12d;
  font-family: "Josefin Sans", sans-serif;
  text-align: start;
  margin-top: 30px;
  text-shadow: 1px 1px 2px rgb(193, 76, 96);
}

.guavachart h1 {
  font-size: 30px;
  font-weight: bold;
  color: #1fc12d;
  font-family: "Josefin Sans", sans-serif;
  text-align: start;
  margin-top: 30px;
  text-shadow: 1px 1px 2px rgb(193, 76, 96);
}

.guava-box {
  padding: 70px 184px;
}

.guava-single {
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  box-shadow: rgba(143, 220, 139, 0.12) 0px 1px 3px, rgb(44, 46, 44) 0px 1px 2px;
  margin-bottom: 30px;
}


@media screen and (max-width: 640px) {

  .guavapacking h1 {
      margin-left: 10px;
  }
  .guavaparat {
    padding-top: 70px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: justify;
  }

  .guavaparat p{
    font-size: 16px;
  }

  .guava-box {
    padding: 0px 0px;
}

.guavachart {
  margin-left: 10px;
}

.row>* {
  flex-shrink: 0;
  max-width: none;
  padding-right: calc(var(--mdb-gutter-x) * 0.5);
  padding-left: calc(var(--mdb-gutter-x) * 0.5);
  margin-top: var(--mdb-gutter-y);
}

.packages {
  margin-left: 30px;
  width: 73%;
}

}