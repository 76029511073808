.content {
  background: url("../images/graden.jpg") center center fixed;
  background-size: cover;
  position: relative;
  height: 100vh;
}

.cen h1 {
  font-size: 2.5rem;
  font-weight: 900;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-align: center;
}

.form-control {
  border: none;
  border-bottom: 1px solid #d9d9d9;
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
}

.form-control:active,
.form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #000;
}

.btn,
.form-control {
  height: 55px;
}

form.border-right {
  padding-right: 6rem !important;
}

.mform {
  display: flex;
}

.head-feedback {
  color: rgb(143, 177, 227);
}

label.error {
  font-size: 12px;
  color: red;
}

#message {
  resize: vertical;
}

#form-message-warning,
#form-message-success {
  display: none;
}

#form-message-warning {
  color: #B90B0B;
}

#form-message-success {
  color: #55A44E;
  font-size: 18px;
  font-weight: bold;
}

.submitting {
  float: left;
  width: 100%;
  padding: 10px 0;
  display: none;
  font-weight: bold;
  font-size: 12px;
  color: #000;
}

.imgcontianer {
  margin-left: 90px;
}

.imgmain {
  flex-wrap: wrap;
}

.ssform {
  margin-left: 50px;
}

.den {
  font: 1em/1.618 Inter, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #224;
  margin-left: 30px;
  width: 1000px;
  margin-top: 8rem;
}

.cen {
  max-width: 300px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1300px;
  height: 550px;
  padding: 25px;
  border: 1px solid rgba(255, 255, 255, .25);
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(15px);
}

.cen-footer {
  font-size: 0.65em;
  color: #446;
}

#submitbutton {
  margin-left: 10rem;
}

.img-img {
  margin-left: 100px;
  filter: drop-shadow(0 0 0.75rem rgb(255, 255, 255));
}

.img-fluid {
  filter: drop-shadow(0 0 0.75rem rgb(255, 255, 255));
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

.animated.flipOutX,
.animated.flipOutY,
.animated.bounceIn,
.animated.bounceOut {
  animation-duration: .75s;
}

@media screen and (max-width: 640px) {
  .cover {
    display: flex;
    justify-content: center;
  }

  .den {
    font: 1em/1.618 Inter, sans-serif;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* Change this line */
    color: #224;
    margin-left: 17px;
    width: 100%;
    margin-top: 6rem;
  }

  .cen {
    max-width: 300px;
  }

  .cen h1 {
    font-size: 1rem;
  }

  .ssform {
    margin-left: 0px;
  }

  #lname {
    margin-top: 25px;
  }

  #submitbutton {
    margin-left: 60px;
  }

  .btn {
    border-radius: 10px;
  }

  .imgcontianer {
    margin-left: 90px;
    display: none;
  }

  form.border-right {
    padding-right: 0 !important;
    border-right: none !important;
  }
}