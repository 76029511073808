.feedbackview-contain{
    font-family: 'Poppins', sans-serif;
    width: 100%;
    height: 110vh;
    padding-top: 90px;
    position: relative;
}

.feedbackview-name{
    text-align: center;
    margin-left: 30px;
    font-weight: 600;
    margin-bottom: 10px;
    color: black;
}