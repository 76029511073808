.my-button {
  transition: all 0.3s;
  display: block;
  padding: 0.75rem 1rem;
  width: 100%;
  color: white;
  font-weight: bold;
  border: none;
  cursor: pointer;
  background: linear-gradient(to right, #6574cd, #748ffc);
  border-radius: 0.25rem;
  text-align: center;
  margin-top: 1.5rem;
}

.my-button:hover {
  background: linear-gradient(to right, #4f46e5, #6a5dd8);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.my-label {
  color: #718096;
  display: block;
  margin-top: 1rem;
}

.my-input {
  border-radius: 0.25rem;
  padding: 0.75rem 0.15rem;
  width: 100%;
  background-color: white;
  border: 1px solid #e2e8f0;
  color: #4a5568;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  outline: none;
  margin-top: 0.25rem;
}

.my-input:focus {
  border-color: #7f9cf5;
  outline: none;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.5);
}

.my-container {
  background-color: #edf2f7;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.my-title {
  color: #4a4949;
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 1rem;
}

.my-title span {
  font-weight: 400;
}

.my-sub-title{
  color: #4a4949;
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
}
.my-box {
  border-top: 0.5rem solid rgba(79, 70, 229, 1);
  border-radius: 0.125rem;
  background-color: white;
  padding: 3rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  width: 24rem;
  align-items: center;
  justify-content: center;
}

.my-title{
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.my-heading {
  color: #4f46e5;
  font-weight: bold;
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.my-form {
  display: block;
  margin-top: 0em;
  align-items: center;
  justify-content: space-around;
}

.unim{
  margin-top: 20px;
  text-align: center;
  height: 100px;
}

.uni {
  text-align: center;
  display: inline-flex;
  margin-bottom: 5px;
}

.unim p {
  background: -webkit-linear-gradient(#5050e9, #f061f7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: -20px;
  margin-left: 10px;
  font-style: oblique;
}

.unim img {
  margin-top: 5px;
  width: 200px;
  height: 55px;
  margin-bottom: 20px;
}

.uniname {
  color: #000000d3;
  margin-left: 10px;
  margin-top: 3px;
}

.uniname h2 {
  font-weight: 700;
}

.uniname h5 {
  font-size: 16px;
  /* font-style: italic; */
  margin-left: 1px;
  margin-top: -22px;
}