.img-fluid {
    max-width: 100%;
    opacity: 0.8;
    max-height: 100%;
}

.video-home {
    max-width: 100%;
    max-height: auto;
}

.video-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.video-element {
    width: 100%;
    height:100%;
    object-fit: cover;
    opacity: 1.6;
}

.fade-in-right {
    opacity: 0;
    transform: translateX(50px);
    /* Adjust the translation distance as needed */
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-in-right.visible {
    opacity: 1;
    transform: translateX(0);
}

.fade-in-left {
    opacity: 0;
    transform: translateX(-200px);
    /* Adjust the translation distance as needed */
    transition: opacity 0.5s ease, transform 2s ease;
}

.fade-in-left.visible {
    opacity: 1;
    transform: translateX(0);
}

.Homeproduct {
    border: 1px solid black;
    width: 98%;
    margin-left: 14px;
    border: none;
    border-radius: 7px;
    padding: 30px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 10px;
}

.homehead p {
    margin-top: 30px;
    font-weight: bold;
    text-align: center;
    font-size: 28px;
    color: #86B04C;
}

.homehead span {
    color: #40bd2d;
    height: 50px;
}

.homehead h3 {
    text-align: center;
    color: #515751;
}

.first-pro:hover {
    border-top: 2px solid blueviolet;
}

.Second-pro:hover {
    border-bottom: 2px solid rgb(164, 246, 153);
}

.Third-pro:hover {
    border-top: 2px solid rgb(214, 67, 243);
}

.Four-pro:hover {
    border-bottom: 2px solid rgb(232, 93, 93);
}

.first-pro p {
    color: blueviolet;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.Second-pro p {
    margin-top: 250px;
    margin-right: 100px;
    color: rgb(74, 213, 94);
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.Third-pro p {
    color: rgb(214, 67, 243);
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.Four-pro p {
    margin-top: 250px;
    margin-right: 100px;
    color: rgb(232, 93, 93);
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.first h1,
p {
    font-family: oblique;
    color: rgb(5, 5, 5);
}

.second {
    margin-bottom: 350px;

    /* margin-right: 600px; */
}

.second span {
    color: rgb(21, 172, 4);
    font-weight: 700;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.second h1,
.second p {
    font-family: oblique;
    color: rgb(255, 249, 249);
    text-shadow: 1px 3px 0px rgba(0, 0, 0, 0.6);
}

.third h1,
p {
    font-family: oblique;
    color: rgb(255, 246, 246);
}

h3 {
    color: black;
}

.title-all {
    margin-bottom: 30px;
}

.title-all h1 {
    font-size: 32px;
    font-weight: 700;
    color: #000000;
}

.title-all p {
    color: #999999;
    font-size: 16px;
}



.special-menu {
    margin-bottom: 40px;
}

.filter-button-group {
    display: inline-block;
}

.filter-button-group button {
    color: #84AF49;
    border: none;
    cursor: pointer;
    padding: 5px 30px;
    font-size: 18px;
}

.filter-button-group button.active {
    background-color: #84AF49;
    color: white;
    border-radius: 5px;
}

.filter-button-group button::before .low-opacity-bg-image {
    isolation: isolate;
    position: relative;
}


.low-opacity-bg-image::after {
    content: "";
    position: absolute;
    inset: 0;
    opacity: 0.5;
    background-size: cover;
    background-position: center;
}

.products-single {
    overflow: hidden;
    position: relative;
    height: 400px;
    border-radius: 10px;
    margin-bottom: 30px;
    background: #f5f5f5;
    box-shadow: 0px 5px 13px 0px rgba(188, 210, 205, 0.75);
}

.products-single .box-img-hover {
    overflow: hidden;
    position: relative;
}

.box-img-hover img {
    margin: 0 auto;
    text-align: center;
    display: block;
}

.type-lb {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 8;
}

.type-lb .sale {
    background: #b0b435;
    color: #ffffff;
    padding: 2px 10px;
    font-weight: 700;
    text-transform: uppercase;
}

.type-lb .new {
    background: #000000;
    color: #ffffff;
    padding: 2px 10px;
    font-weight: 700;
    text-transform: uppercase;
}

.why-text {
    background: #f5f5f5;
    padding: 10px;
}
.spicecard{
    height: 420px;
}

.why-text p {
    text-align: justify;
    font-size: 17px;
    color: #000000;
}

.mask-icon {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
}


.indian {
    flex: 0 0 auto;
    width: 25%;
    margin-left: 470px;
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
    .video-container {
        min-height: 200px;
        /* Adjust the height for mobile if needed */
    }

    .maincontain {
        overflow: hidden;
        color: black;
    }

    .filter-button-group button {
        padding: 10px 15px;
        font-size: 12px;
        font-weight: 600;
    }

    .img-fluid {
        max-width: 100%;
        height: auto;
        opacity: 1.8;
    }

    .why-text p {
        font-family: sans-serif;
    }

    .products-single {
        height: 425px;
    }

    .Homeproduct {
        border: none;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }


    .first-pro p {
        color: blueviolet;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }

    .Second-pro img {
        margin-left: -25px;
    }

    .Second-pro p {
        margin-top: 25px;
        margin-right: 0;

    }

    .Four-pro img {
        margin-left: -25px;
    }

    .second h1 {
        font-family: oblique;
        font-size: 19px;
        color: rgb(255, 249, 249);
        text-shadow: 1px 3px 0px rgba(0, 0, 0, 0.6);
    }

    .second {
        display: block;
        margin-bottom: 0px;
        margin-right: 0px;
    }

    .Four-pro p {
        margin-top: 0px;
        margin-right: 0;
    }

    .indian {
        margin-left: 0px;
    }
}